import { IconProps } from '../type'

const Twitter: React.FC<IconProps> = ({ color = '#fff', fontSize = '.41rem', width = '.39rem', height ='.41rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="media-icon" width="39" height="41" viewBox="0 0 39 41" fill="none" style={{ fontSize: fontSize, width: width, height: height }}>
      <path d="M23.2102 17.3606L37.7289 0H34.2875L21.682 15.0742L11.6132 0H0L15.2259 22.7946L0 41H3.44077L16.7534 25.0814L27.3868 41H39L23.2095 17.3606H23.2102ZM18.4977 22.9955L16.9552 20.7255L4.68 2.66419H9.9645L19.8705 17.2405L21.4136 19.5092L34.2901 38.4558H29.0056L18.4977 22.9962V22.9955Z" fill={color}/>
    </svg>
  )
}

export default Twitter
