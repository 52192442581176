import { IconProps } from '../type'

const Btc: React.FC<IconProps> = ({ fontSize = '.45rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="btc-svg" width="45" height="45" viewBox="0 0 45 45" fill="none" style={{ fontSize: fontSize, width: '.45rem', height: '.45rem' }}>
      <path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" fill="#FF7D00"/>
      <path d="M33.8718 19.3833C34.3954 15.9805 31.7778 14.147 28.2442 12.9267L29.3782 8.30274L26.5862 7.60466L25.4521 12.0977C24.7105 11.9232 23.9689 11.7488 23.1837 11.574L24.318 7.03691L21.5257 6.33911L20.3914 10.9209C19.7806 10.79 19.1699 10.6591 18.6027 10.4846L14.7202 9.52389L13.9785 12.5339C13.9785 12.5339 16.0725 13.0137 16.0275 13.0576C17.1619 13.3629 17.3799 14.1046 17.3366 14.6717L16.0275 19.9505C16.133 19.9648 16.2358 19.9942 16.3329 20.0379L16.0275 19.9505L14.1965 27.3232C14.1174 27.589 13.9318 27.8101 13.6838 27.9341C13.4358 28.0582 13.1475 28.0739 12.8874 27.9778C12.931 28.0213 10.8384 27.4541 10.8384 27.4541L9.44141 30.6822L13.106 31.5982C13.8041 31.7727 14.4583 31.9471 15.1129 32.1219L13.935 36.7896L16.727 37.4877L17.9049 32.9059C18.6901 33.1239 19.4317 33.2986 20.1298 33.4731L18.9955 38.0549L21.7875 38.753L22.9654 34.0861C27.7644 35.0021 31.3851 34.6097 32.8689 30.2906C34.0904 26.8004 32.8254 24.7938 30.2952 23.485C32.1273 23.0923 33.5229 21.87 33.8718 19.3833ZM27.4587 28.3702C26.5862 31.8603 20.6967 29.9844 18.8207 29.5045L20.3475 23.3097C22.2673 23.7895 28.3751 24.7491 27.4587 28.3702ZM28.3312 19.3398C27.5461 22.5245 22.6598 20.9104 21.0459 20.5177L22.4418 14.89C24.0559 15.2827 29.1602 16.0244 28.3312 19.3398Z" fill="white"/>
    </svg>
  )
}

export default Btc
