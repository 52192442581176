import { ArrowRight, ArrowIcon, Twitter, Telegram, Github, Btc, HoverArrow } from '../../components/Svg'
import './index.scss'

export default function Home() {

  return (
    <div className="lendb-home-page">
      <div className="banner-box">
        <div className="main-w">
          <div className="logo-box">
            <div className="logo-wapper flex jc-center ai-center">
              <div className="logo"></div>
            </div>
            <div className="to-app ff-500 cursor-select" onClick={() => window.open('https://app.lenb.io/', '_blank', 'noreferrer')}>Launch App <ArrowRight /></div>
          </div>
          <div className="content-box">
            <p className="title ff-700">LenB: A Money Market for <span style={{ whiteSpace: 'nowrap' }}>Bitcoin-Related</span> Assets</p>
            <p className="desc ff-500">Our mission is to create a user-friendly and secure infrastructure platform for lending and saving Bitcoin-related assets. When conditions are right, we will migrate our platform to the Bitcoin network, offering native decentralized financial services to Bitcoin network users.</p>
            <div onClick={() => window.open('https://app.lenb.io/', '_blank', 'noreferrer')}><ArrowIcon /></div>
          </div>
        </div>
        <div className="arrow-bottom"></div>
      </div>
      <div className="main-box">
        <div className="main-w">
          <div className="join-box">
            <p className="join-title ff-500">Join Us</p>
            <p className="join-desc">Join our community building the money market within the Bitcoin network.</p>
            <div className="media-box">
              <div className="item">
                <div className="icon flex jc-center ai-center cursor-select" onClick={() => window.open('https://twitter.com/LenB_Market', '_blank', 'noreferrer')}><Twitter /><HoverArrow /></div>
                <p className="desc color-grey">Follow @LenB_Market to get the latest news and updates.</p>
              </div>
              <div className="line"></div> 
              <div className="item">
                <div className="icon flex jc-center ai-center cursor-select" onClick={() => window.open('https://t.me/LenB_Offcial', '_blank', 'noreferrer')}><Telegram /><HoverArrow /></div>
                <p className="desc color-grey">Ask questions and chat with our team and coummunity.</p>
              </div>
            </div>
          </div>
          <div className="btc-box">
            <Btc />
            <p className="btc-title ff-500 text-center">Stay tuned for the upcoming Bitcoin network service!</p>
           </div>
        </div>
      </div>
      <div className="footer-box">
        <div className="main-w">
          <div className="img-box">
            <div className="logo"></div>
            <div className="media flex ai-center">
              <div onClick={() => window.open('https://t.me/LenB_Offcial', '_blank', 'noreferrer')}><Telegram fontSize='.26rem' width='.3rem' color='#9D9D9D' /></div>
              <div onClick={() => window.open('https://twitter.com/LenB_Market', '_blank', 'noreferrer')}><Twitter fontSize='.23rem' width='.25rem' color='#9D9D9D' /></div>
              <div onClick={() => window.open('https://github.com/0xLenB', '_blank', 'noreferrer')}><Github /></div>
            </div>
          </div>
          <div className="doc-link">
            <a href="http://" target="_blank" rel="noopener noreferrer">Bug Bounty</a>
            <a href="http://" target="_blank" rel="noopener noreferrer">Docs</a>
          </div>
        </div>
      </div>
    </div>
  )
}
