import { IconProps } from '../type'

const ArrowIcon: React.FC<IconProps> = ({ fontSize = '.76rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none" style={{ fontSize: fontSize, width: '.76rem', height: '.76rem' }}>
      <circle cx="38" cy="38" r="37" stroke="white" strokeWidth="2"/>
      <path d="M28.5 46.5L44 31" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33 30H45V42" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowIcon
