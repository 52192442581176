import { IconProps } from '../type'

const Github: React.FC<IconProps> = ({ color = '#9D9D9D', fontSize = '.32rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none" style={{ fontSize: fontSize, width: '.32rem', height: '.32rem' }}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1857 0C7.23543 0 0 7.28878 0 16.306C0 23.5139 4.63599 29.6153 11.0673 31.7748C11.8714 31.9371 12.166 31.4239 12.166 30.9922C12.166 30.6142 12.1395 29.3184 12.1395 27.9684C7.63698 28.9404 6.69938 26.0246 6.69938 26.0246C5.9758 24.1348 4.90369 23.6491 4.90369 23.6491C3.43003 22.6502 5.01103 22.6502 5.01103 22.6502C6.64571 22.7582 7.50346 24.324 7.50346 24.324C8.95028 26.8075 11.2817 26.1057 12.2196 25.6737C12.3535 24.6208 12.7825 23.8919 13.2381 23.4871C9.64703 23.1091 5.86879 21.7053 5.86879 15.4419C5.86879 13.6602 6.51153 12.2024 7.52997 11.0687C7.36928 10.6638 6.80639 8.98971 7.69098 6.74907C7.69098 6.74907 9.05763 6.31705 12.1391 8.42284C13.4584 8.06591 14.819 7.88434 16.1857 7.88281C17.5524 7.88281 18.9455 8.07199 20.232 8.42284C23.3138 6.31705 24.6805 6.74907 24.6805 6.74907C25.565 8.98971 25.0018 10.6638 24.8411 11.0687C25.8864 12.2024 26.5026 13.6602 26.5026 15.4419C26.5026 21.7053 22.7244 23.0819 19.1065 23.4871C19.6963 23.9999 20.2051 24.9717 20.2051 26.5106C20.2051 28.6972 20.1786 30.4522 20.1786 30.9919C20.1786 31.4239 20.4735 31.9371 21.2773 31.7751C27.7086 29.615 32.3447 23.5139 32.3447 16.306C32.3711 7.28878 25.1092 0 16.1857 0Z" fill={color}/>
    </svg>
  )
}

export default Github
