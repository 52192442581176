import { IconProps } from '../type'

const ArrowRight: React.FC<IconProps> = ({ fontSize = '.11rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" style={{ fontSize: fontSize, width: '.11rem', height: '.11rem' }}>
      <path d="M1.00045 5.06437L8.66148 5.06419" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.27441 1L9.33889 5.06448L5.27441 9.12896" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowRight
