import { IconProps } from '../type'

const Telegram: React.FC<IconProps> = ({ color = '#fff', fontSize = '.41rem', width = '.49rem', height ='.41rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="media-icon tele" width="49" height="41" viewBox="0 0 49 41" fill="none" style={{ fontSize: fontSize, width: width, height: height }}>
      <path d="M45.7019 0.26943L2.28521 16.9682C-0.678152 18.1602 -0.65172 19.8133 1.74188 20.4928L12.8875 24.0159L38.6606 7.77686C39.8838 7.04618 40.9969 7.45325 40.0732 8.29229L19.1946 27.0528L18.4339 38.5197C19.5485 38.5197 20.0639 38.0057 20.6895 37.3805L26.1008 32.1486L37.3551 40.4438C39.4212 41.583 40.9176 40.987 41.433 38.5197L48.8282 3.79252C49.4362 1.37058 48.3466 0 46.8752 0C46.5007 0 46.1042 0.0878576 45.7019 0.26943Z" fill={color}/>
    </svg>
  )
}

export default Telegram
