import { IconProps } from '../type'

const HoverArrow: React.FC<IconProps> = ({ fontSize = '.58rem' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="hover-arrow" width="58" height="58" viewBox="0 0 58 58" fill="none" style={{ fontSize: fontSize, width: '.58rem', height: '.58rem' }}>
      <circle cx="29" cy="29" r="28.5" fill="#0A0A0A" stroke="#252525"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.0565 23.4435C35.3415 23.7284 35.3415 24.1905 35.0565 24.4755L23.7457 35.7863C23.4607 36.0712 22.9987 36.0712 22.7137 35.7863C22.4288 35.5013 22.4288 35.0392 22.7137 34.7543L34.0245 23.4435C34.3095 23.1585 34.7715 23.1585 35.0565 23.4435Z" fill="#F5A601"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.7842 23.2297C25.7842 22.8267 26.1109 22.5 26.5139 22.5H35.2706C35.6737 22.5 36.0004 22.8267 36.0004 23.2297V31.9865C36.0004 32.3895 35.6737 32.7162 35.2706 32.7162C34.8676 32.7162 34.5409 32.3895 34.5409 31.9865V23.9595H26.5139C26.1109 23.9595 25.7842 23.6327 25.7842 23.2297Z" fill="#F5A601"/>
  </svg>
  )
}

export default HoverArrow
